@use '@angular/material' as mat;
@import '~ngx-toastr/toastr';
@import 'assets/colors.scss';
// it must me above import to work
$pretty--colors: (
  primary: $main,
  warning: $mustard,
  secondary: $secondary,
);

.pretty.p-smaller-break-word {
  label:before,
  label:after,
  .icon,
  .svg,
  .img {
    font-size: 12px !important;
    top: calc((0% - (100% - 2.6em)) - 35%) !important;
  }

  label {
    text-indent: 1em;
  }
}

@import '../node_modules/pretty-checkbox/src/pretty-checkbox';

$custom-typography: mat.define-typography-config(
  $font-family: '"Raleway"',
  $headline: mat.define-typography-level(32px, 48px, 700),
  $body-1: mat.define-typography-level(14px, 24px, 400, '"Raleway"', 0.14px),
);

@include mat.typography-hierarchy($custom-typography);
@include mat.checkbox-typography($custom-typography);
@include mat.all-component-typographies($custom-typography);
@include mat.core($custom-typography);

$bridge2impact-color-primary: (
  50: $main,
  100: $main,
  200: $main,
  contrast: (
    50: white,
    100: white,
    200: white,
  ),
);

$bridge2impact-color-accent: (
  50: $secondary,
  100: $secondary,
  200: $secondary,
  contrast: (
    50: white,
    100: white,
    200: white,
  ),
);

$bridge2impact-color-ternary: (
  50: $ternary,
  100: $ternary,
  200: $ternary,
  contrast: (
    50: white,
    100: white,
    200: white,
  ),
);

$bridge2impact-color-warn: (
  50: $delete-color,
  100: $delete-color,
  200: $delete-color,
  contrast: (
    50: white,
    100: white,
    200: white,
  ),
);

$marketplace-frontend-primary: mat.define-palette($bridge2impact-color-primary, 100, 50, 200);
$marketplace-frontend-accent: mat.define-palette($bridge2impact-color-accent, 100, 50, 200);
$marketplace-frontend-warn: mat.define-palette($bridge2impact-color-warn, 100, 50, 200);
$marketplace-frontend-theme: mat.define-light-theme($marketplace-frontend-primary, $marketplace-frontend-accent, $marketplace-frontend-warn);
@include mat.all-component-themes($marketplace-frontend-theme);

/* You can add global styles to this file, and also import other style files */
.container {
  //height: 100%;
  max-width: 1440px;
  margin: auto;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  -webkit-print-color-adjust: exact !important;
  print-color-adjust: exact !important;
}

button:focus,
video {
  outline: none !important;
}

a {
  text-decoration: none !important;
}

a:hover {
  color: initial;
}

.link {
  font-weight: bold;
  cursor: pointer;
  color: $mustard !important;
}

.link-main {
  font-weight: bold;
  cursor: pointer;
  color: $link !important;
}

.text-blue {
  color: $link !important;
}

.text-underline {
  text-decoration: underline !important;
}

.inherit-height {
  height: 100% !important;
}

.pointer {
  cursor: pointer;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.vertical-center {
  margin: 0;
  display: flex;
  align-items: center;
}

.gray-container {
  background-color: $light-gray;
}

.text-gray {
  color: $gray;
}

.plr-55px {
  padding: 0 55px;
}

.py-15 {
  padding-left: 15px;
  padding-right: 15px;
}

.card {
  border: 1px solid $input-color;
  border-radius: 0;
}

.shadowed-card {
  border: 1px solid #d3d3d300;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 8px 0px lightgrey;
  width: 100%;
}

.no-border {
  border: none;
}

.text-justify {
  text-align: justify;
  text-justify: inter-word;
}

.page-title {
  font-size: 28px;
  letter-spacing: 0px;
  font-weight: 700;
  margin-top: 35px;
  text-transform: uppercase;
  line-height: 30px;
}

.font-18 {
  font-size: 18px !important;
}

.p-section {
  font-size: 16px;
}

.break-word {
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
}

.mat-progress-bar {
  border-radius: 2px;
  height: 5px !important;
}

.mat-progress-bar-fill::after {
  background-color: $main;
}

.mat-progress-bar-buffer {
  background: $light-gray;
}

.action-btns {
  button {
    line-height: 26px;
    padding: 0 18px;
  }
}

.mat-button-base {
  border-radius: 2px !important;
}

.mat-mustard {
  background: $mustard;
  color: white;
}

.mat-main-button {
  font-size: 14px !important;
  outline: 0 !important;
  letter-spacing: 0 !important;
  border-radius: 2px !important;
}

.mat-outline-button {
  background-color: white;
  font-size: 14px !important;
  outline: 0 !important;
  letter-spacing: 0 !important;
  border-radius: 2px !important;
}

.extra-padding {
  padding: 0 35px !important;
}

.primary-border {
  border-color: $main !important;
}

.accent-border {
  border-color: $secondary !important;
}

.warn-border {
  border-color: $delete-color !important;
}

.gray-border {
  border-color: $file-card-text !important;
}

.blue-border {
  border-color: $link !important;
}

.blue-text {
  color: $link !important;
}

.secondary-text {
  color: $secondary !important;
}

.warn-text {
  color: $delete-color !important;
}

.slick-slide:focus {
  outline: none !important;
}

.uppercase {
  text-transform: uppercase;
}

.text-main {
  color: $main;
  font-weight: bold;
}

.content-stepper {
  height: 100%;
  width: 100%;
}

.error {
  font-size: 75%;
  line-height: 10px;
  margin-bottom: 10px;
}

.small-text {
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 0.8rem;
}

.logo {
  width: 150px;
  height: 150px;
  object-fit: cover;
}

.activeLink {
  background: #e3eee4;
  color: $secondary;
  font-weight: bold;
}

.mat-tab-label {
  padding: 0 !important;
  min-width: 140px !important;
}

.back-icon {
  width: 20px;
  height: 20px;
  color: $main;
}

.auth-input {
  margin-bottom: 1rem;
  margin-top: 1rem;

  .mat-error {
    color: #f65f5f;
  }

  mat-label {
    font-size: 12px;
    color: $mustard;
  }

  .mat-form-field-suffix .mat-icon {
    font-size: 16px;
  }

  .mat-form-field-wrapper {
    padding-bottom: 0.25rem;
  }

  .mat-form-field-flex {
    background: white;
    border-radius: 2px;
  }

  .mat-form-field-underline {
    display: none;
  }

  .mat-form-field-appearance-standard .mat-form-field-flex {
    padding: 0.2rem 0.75rem 0;
  }

  .mat-form-field-hide-placeholder .mat-form-field-label-wrapper {
    top: -12px;
    font-size: 10px;
    font-weight: bold;
  }
}

.admin-input {
  .mat-stroked-button {
    border: 1px solid #272727;
    border-radius: 2px;
    line-height: 26px;
  }

  .mat-form-field-appearance-outline {
    height: 40px !important;
    font-size: 12px;
  }

  .mat-form-field-flex {
    height: 40px !important;
    padding-top: 3px;
  }

  .mat-form-field-wrapper {
    height: 40px !important;
  }

  .mat-form-field-infix {
    padding-top: 2px !important;
    padding-bottom: 3px !important;
    height: 40px !important;
  }

  .mat-input-element {
    line-height: 1.9 !important;
  }

  .mat-form-field {
    margin-bottom: 5px;
    width: 100% !important;

    .mat-form-field-outline-start {
      border-radius: 2px 0 0 2px !important;
    }

    .mat-form-field-outline-end {
      border-radius: 0 2px 2px 0 !important;
    }

    .mat-form-field-outline-gap {
      border-top-style: solid !important;
      border-top-color: unset !important;
    }

    .mat-form-field-label-wrapper {
      top: -15px !important;
      text-align: left;
      font-size: 14px;
      letter-spacing: 0px;
      opacity: 1;
    }

    .mat-form-field-wrapper {
      .mat-form-field-subscript-wrapper {
        position: inherit !important;
        overflow: hidden !important;
        margin: 0 6px 0 6px !important;
      }
    }
  }

  .readonly {
    pointer-events: none;
    color: $subheader;
  }
}

.admin-textarea {
  .mat-form-field {
    width: 100% !important;

    .mat-form-field-infix {
      padding-top: 0 !important;

      .mat-input-element {
        text-align: left;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 1.4;
        color: #707070;
        opacity: 1;
      }
    }

    .mat-form-field-outline-start {
      border-radius: 2px 0 0 2px !important;
    }

    .mat-form-field-outline-end {
      border-radius: 0 2px 2px 0 !important;
    }

    .mat-form-field-outline-gap {
      border-top-style: solid !important;
      border-top-color: unset !important;
    }

    .mat-form-field-label-wrapper {
      top: -15px !important;
      text-align: left;
      font-size: 14px;
      letter-spacing: 0;
      opacity: 1;
    }

    .mat-form-field-wrapper {
      padding-bottom: 0;

      .mat-form-field-appearance-outline .mat-form-field-infix {
        padding: 0 0 1em 0 !important;
      }

      .mat-form-field-subscript-wrapper {
        position: inherit !important;
        overflow: hidden !important;
        margin: 0 6px 0 6px !important;
      }
    }
  }
}

.mat-divider {
  border-top-color: $divider-color;
}

.mat-expansion-panel {
  box-shadow: none !important;
}

.mat-tooltip {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  opacity: 1;
  text-align: left;
  font-size: 12px;
  letter-spacing: 0px !important;
  color: #272727 !important;
  padding: 10px 20px 10px 20px !important;
}

.modal-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  .mat-stroked-button {
    height: 30px !important;
    border-radius: 2px;
    line-height: 30px !important;
  }
}

.carousel-control-next,
.carousel-control-prev {
  background: rgb(0, 0, 0);
  background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 212, 255, 0) 100%);
  top: initial;
  height: 15%;
  border-top: 1px solid;
}

.carousel-control-next:hover,
.carousel-control-prev:hover {
  border-color: #ffffff;
}

.carousel-control-prev {
  border-right: 1px solid;
}

.carousel-control-next {
  border-left: 1px solid;
}

.nav-menu.mat-menu-panel {
  min-width: 300px !important;
  min-height: 93vh !important;
  opacity: 0.95;
  background-color: $main;
  color: #ffffff;

  a:first-of-type {
    margin-top: 17px;
  }

  a:last-of-type {
    margin-bottom: 17px;
  }

  a {
    display: block;
    font-size: 1.2em;
    padding-top: 20px;
    padding-bottom: 20px;
    text-align: center;

    label {
      margin: 0;
    }
  }
}

.mat-dialog-container {
  // padding: 10px !important;
  overflow-x: hidden !important;
}

.custom_menu + * div.cdk-overlay-pane > div.mat-menu-panel {
  background: #ffffff;
  margin-top: 16px;
  min-width: 270px !important;
  max-width: 270px !important;
  min-height: 360px !important;
  max-height: 360px !important;
}

.mat-option-text {
  font-size: 14px;
}

.label {
  text-align: left;
  font-size: 16px;
  font-weight: 700;
  margin: 1rem 0 0.8rem;
  color: $dark-text;
  opacity: 1;
  transition: opacity 1s;
}

.optional {
  color: #8b8b8b;
  font-size: 14px;
  font-weight: 400;
}

.active-tab {
  font-weight: bold;
  border-radius: 0 !important;
  border-bottom: 2px solid $link !important;
}

.learn-more-btn {
  font-size: 18px;
  color: $link;
  cursor: pointer;
  font-weight: 400;
}

.name-link-btn {
  font-size: 20px;
  color: $link;
  cursor: pointer;
}

.navigate-link {
  color: $link;
}

.back-btn {
  color: $main;
}

.pl-20 {
  padding-left: 20px;
}

.pr-20 {
  padding-right: 20px;
}

.my-modal-header {
  font-weight: bold;
  font-size: 16px;
  text-align: center;
  letter-spacing: 0px;
  opacity: 1;
  margin-top: 16px;
}

.borderless th {
  border-top-width: 0 !important;
  border-bottom-width: 1px !important;
}

.borderless-top th {
  border-top-width: 2px !important;
}

.borderless td {
  border: none !important;
}

table.table tbody td {
  vertical-align: middle !important;
}

thead {
  background: $divider-color;
  font-weight: bold;
}

.th-text {
  padding: 10px;
}

.user-avatar {
  width: 30px;
  height: 30px;
}

.icon-container {
  top: 368px;
  left: 494px;
  width: 25px;
  height: 25px;
  background: #ffffff 0 0 no-repeat padding-box;
  border: 0px;
  opacity: 1;
  color: $main;
  text-decoration: none;

  &:nth-child(2) {
    margin: 0 6px;
  }

  &:hover {
    cursor: pointer;
    background: $light-gray;
  }

  &:active {
    background: $light-gray;
  }
}

.fa-edit {
  color: $secondary !important;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(340px, 1fr));
  grid-column-gap: 10px;
  grid-row-gap: 20px;
}

.taller-button {
  height: 40px !important;
}

// Iphone 5/SE - 320px
@media (max-width: 340px) {
  .grid-container {
    grid-template-columns: 1fr;
  }
}

// Media queries
@media (max-width: 1200px) {
  .plr-55px {
    padding-left: 35px;
    padding-right: 35px;
  }
}

@media (max-width: 768px) {
  .content-stepper {
    height: 100%;
    width: 100%;
  }
}

@media (max-width: 500px) {
  .plr-55px {
    padding: 0 5px;
  }
  .menu-button {
    min-width: 0 !important;
    padding: 0 !important;
  }

  .action-btns button {
    line-height: 30px;
    padding: 3px 8px;
  }

  .mat-main-button {
    font-size: 12px !important;
    padding: 0 5px !important;
    min-width: 40px !important;
    line-height: 30px !important;
  }

  .mat-outline-button {
    font-size: 12px !important;
    padding: 0 5px !important;
    min-width: 40px !important;
  }

  .sign-button {
    min-width: 70px !important;
  }
}

@media (max-width: 300px) {
  .tabs-btn button {
    font-size: 12px;
  }
  .nav-menu.mat-menu-panel {
    min-width: 260px !important;
  }
}

@media (max-height: 500px) {
  .tabs-btn button {
    padding: 0 10px;
  }
  .nav-menu.mat-menu-panel a {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}
