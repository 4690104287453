$main: #000000;
$secondary: #428b4d;
$mustard: #a78b58;
$ternary: #575757;
$gray: #8b8b8b;
$light-gray: #fafafa;
$link: #2a4c7b;
$input-color: #eeeeee;
$dark-text: #272727;
$file-card-text: #575757;
$delete-color: #d30000;
$subheader: #b7b7b7;
$border-gray: #707070;
$divider-color: #f8f8f8;
